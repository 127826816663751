import { compose, filter, map } from "ramda"
import {
  DesktopIconLight,
  FileCertificate,
  FileLinesIconLight,
  ReceiptIconLight,
  ServerIconLight,
  TicketIconLight,
  UploadIcon,
  AIIconLight,
  AppleIcon,
  AndroidIcon,
  NinjaPatchingSvg,
} from "@ninjaone/icons"
import {
  APP_LOGOS,
  isFeatureEnabled,
  isITCloud,
  isAppleMDMFeatureEnabled,
  isAndroidMDMFeatureEnabled,
  isSystemAdmin,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  isUserAllowedUseTicketingAdministrativeActions,
  user,
  WEBHOOK_LOGO,
} from "js/includes/common/utils"
import { faNinjaControl } from "media/icons"
import { localizationKey, localized, localizedF } from "js/includes/common/utils/ssrAndWebUtils"
import { hasDocumentationConfigurationAccess } from "js/includes/common/services/userPermissions"
import { ninjaResourceCdn } from "js/includes/common/services/ninjaResourcesCdn"
import { StyledFontAwesomeIcon } from "js/includes/components/Styled"
import {
  appDescriptionToken as warrantyTrackingAppDescriptionToken,
  productNameToken as warrantyTrackingProductNameToken,
} from "js/includes/configuration/integrations/warrantyTracking/common"
import { getSuggestedArticles } from "js/includes/components/HelpDropdown/SuggestedArticles/common"
import showModal from "js/includes/common/services/showModal"
import GetStartedModal from "js/includes/configuration/integrations/backup/lockhart/GetStartedModal"
import { ticketingConfigurationSegments } from "js/includes/configuration/integrations/ticketing/common/utils"
import {
  canViewAtLeastOneAppleConnection,
  canViewAtLeastOneConnection,
} from "js/includes/configuration/integrations/mdm/permissions"
import { EntitySubType } from "js/includes/editors/User/Permissions/common"

export const APPS_URL = "/administration/apps"

export const PSA_TYPES = {
  AUTOTASK: "AUTOTASK",
  ACCELO: "ACCELO",
  CONNECTWISE: "CONNECTWISE",
  REPAIRSHOPR: "REPAIR_SHOPR",
  GENERIC_WEBHOOK: "GENERIC_WEBHOOK",
  COMPUTICATE: "COMPUTICATE",
  HARMONY: "HARMONY",
  PROMYS: "PROMYS",
  SHERPADESK: "SHERPADESK",
  SERVICE_NOW: "SERVICE_NOW",
  ZENDESK: "ZENDESK",
}

export const VULNERABILITY_VENDORS = {
  RAPID7: "Rapid7",
  QUALYS: "Qualys",
  TENABLE: "Tenable",
  SPOTLIGHT: "Spotlight",
}

export const psaApps = {
  AUTOTASK: {
    label: () => `Autotask ${localized("PSA")}`,
    description: localizedF(
      "Ninja’s two-way integration with Autotask enables organization, device, and product mapping while syncing tickets, alerts, and key device actions.",
    ),
    helpArticles: getSuggestedArticles(["206623446"]),
    logo: APP_LOGOS.AUTOTASK,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/autotask`,
  },
  CONNECTWISE: {
    label: localizedF("ConnectWise Manage"),
    logo: APP_LOGOS.CONNECTWISE,
    description: localizedF(
      "Ninja’s two-way integration with ConnectWise Manage enables organization, device, and product mapping while syncing tickets, alerts, and key device actions.",
    ),
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/connectWiseManage`,
  },
  ACCELO: {
    label: () => "Accelo",
    description: localizedF(
      "Sync tickets, quickly move between platforms, and launch remote access sessions in Accelo with NinjaOne’s Accelo integration.",
    ),
    helpArticles: getSuggestedArticles(["360004037291"]),
    logo: APP_LOGOS.ACCELO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/accelo`,
  },
  REPAIR_SHOPR: {
    label: () => "RepairShopr",
    description: localizedF(
      "Run your MSP business through a seamless two-way integration between NinjaOne and RepairShopr.",
    ),
    helpArticles: getSuggestedArticles(["214971106"]),
    logo: APP_LOGOS.REPAIR_SHOPR,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/repairshopr`,
  },
  GENERIC_WEBHOOK: {
    label: localizedF("Generic Webhook"),
    description: () => null, // TODO: Add description
    logo: WEBHOOK_LOGO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/genericWebhook`,
    isWebhook: true,
  },
  COMPUTICATE: {
    label: () => "Computicate",
    description: () => null, // TODO: Add description
    logo: WEBHOOK_LOGO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/computicate`,
    isWebhook: true,
  },
  HARMONY: {
    label: () => "Harmony",
    description: () => null, // TODO: Add description
    logo: WEBHOOK_LOGO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/harmony`,
    isWebhook: true,
  },
  PROMYS: {
    label: () => "Promys",
    description: () => null, // TODO: Add description
    logo: WEBHOOK_LOGO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/promys`,
    isWebhook: true,
  },
  SHERPADESK: {
    label: () => "SherpaDesk",
    description: () => null, // TODO: Add description
    logo: WEBHOOK_LOGO,
    hide: () => !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/sherpadesk`,
    isWebhook: true,
  },
  SERVICE_NOW: {
    label: () => "ServiceNow",
    description: localizedF(
      "A bi-directional integration with ServiceNow allows incident management; creating, updating and closing incidents automatically from NinjaOne policy conditions.",
    ),
    helpArticles: getSuggestedArticles(["23055075026701"]),
    logo: APP_LOGOS.SERVICE_NOW,
    hide: () => !user("canConfigurePsa") || !isFeatureEnabled("service_now"),
    configRoute: `${APPS_URL}/serviceNow`,
  },
  // TODO: add helpArticles once ready & approved
  ZENDESK: {
    label: () => "Zendesk",
    description: localizedF(
      "A bi-directional integration with Zendesk allows incident management; creating, updating and closing incidents automatically from NinjaOne policy conditions.",
    ),
    logo: APP_LOGOS.ZENDESK,
    hide: () => !isFeatureEnabled("zendesk") || !user("canConfigurePsa"),
    configRoute: `${APPS_URL}/zendesk`,
  },
}

export const AppName = {
  MDM_APPLE: "MDM_APPLE",
  MDM_ANDROID: "MDM_ANDROID",
}

export const appInfo = {
  NINJA_TICKETING: {
    label: () => "NinjaOne Ticketing",
    description: localizedF(
      "Improve ticket resolution times and service delivery outcomes with context-rich, endpoint-aware, automated IT ticketing.",
    ),
    helpArticles: getSuggestedArticles(["360056423691"]),
    logo: APP_LOGOS.NINJA,
    icon: <TicketIconLight size="xl" />,
    hide: () => !isUserAllowedUseTicketingAdministrativeActions(),
    isNinja: true,
    baseRoute: `${APPS_URL}/ticketing`,
    configRoute: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.general}`,
    subtabs: [
      {
        label: localizedF("General"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.general}`,
      },
      {
        label: localizedF("Inbound Email Addresses"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.inboundEmailAddresses}`,
      },
      {
        label: localizedF("Allowlist"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.allowlist}`,
      },
      {
        label: localizedF("Blocklist"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.blocklist}`,
      },
      {
        label: localizedF("Boards"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.boards}`,
      },
      {
        label: localizedF("Active Fields"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.activeFields}`,
      },
      {
        label: localizedF("Inactive Fields"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.inactiveFields}`,
      },
      {
        label: localizedF("Active Forms"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.activeForms}`,
      },
      {
        label: localizedF("Inactive Forms"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.inactiveForms}`,
      },
      {
        label: localizedF("Tags"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.tags}`,
      },
      {
        label: localizedF("Activity Rules"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.activityRules}`,
      },
      {
        label: localizedF("Condition Rules"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.conditionRules}`,
      },
      {
        label: localizedF("Script Rules"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.scriptRules}`,
      },
      {
        label: localizedF("Event Based"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.eventBased}`,
      },
      {
        label: localizedF("Time Based"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.timeBased}`,
      },
      {
        label: localizedF("Response Templates"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.responseTemplates}`,
      },
      {
        label: localizedF("Custom Status"),
        route: `${APPS_URL}/ticketing/${ticketingConfigurationSegments.customStatus}`,
      },
    ],
  },
  DOCUMENTATION: {
    label: () => "NinjaOne Documentation",
    description: localizedF(
      "Document, standardize, and use contextual information about your managed IT environments directly in NinjaOne.",
    ),
    helpArticles: getSuggestedArticles(["360061218431"]),
    logo: APP_LOGOS.NINJA,
    icon: <FileLinesIconLight size="xl" />,
    hide: () => !hasDocumentationConfigurationAccess(),
    isNinja: true,
    configRoute: `${APPS_URL}/documentation`,
    baseRoute: `${APPS_URL}/documentation`,
    subtabs: [
      {
        label: localizedF("Checklists"),
        route: `${APPS_URL}/documentation/checklists`,
      },
      {
        label: localizedF("Import history"),
        route: `${APPS_URL}/documentation/history`,
      },
      {
        label: localizedF("Public links history"),
        route: `${APPS_URL}/documentation/accessHistory`,
      },
      {
        label: localizedF("Shared links"),
        route: `${APPS_URL}/documentation/sharedLinks`,
      },
      {
        label: localizedF("End user roles"),
        route: `${APPS_URL}/documentation/sharedFolders`,
      },
      {
        label: localizedF("TOTP"),
        route: `${APPS_URL}/documentation/totp`,
      },
    ],
  },
  [AppName.MDM_APPLE]: {
    label: () => "NinjaOne MDM Apple",
    logo: APP_LOGOS.NINJA,
    icon: <AppleIcon size="xl" />,
    description: localizedF("Manage Apple devices for all organizations."),
    helpArticles: getSuggestedArticles(["11451699609101"]),
    hide: () => !canViewAtLeastOneAppleConnection() || !isAppleMDMFeatureEnabled(),
    isNinja: true,
    configRoute: `${APPS_URL}/mdmApple`,
  },
  [AppName.MDM_ANDROID]: {
    label: () => "NinjaOne MDM Android",
    logo: APP_LOGOS.NINJA,
    icon: <AndroidIcon size="lg" />,
    description: localizedF("Manage Android devices for all organizations."),
    helpArticles: getSuggestedArticles(["11451699609101"]),
    hide: () => !canViewAtLeastOneConnection(EntitySubType.AndroidConnection) || !isAndroidMDMFeatureEnabled(),
    isNinja: true,
    configRoute: `${APPS_URL}/mdmAndroid`,
  },
  REMOTE_SUPPORT: {
    label: () => "Remote Support",
    logo: APP_LOGOS.NINJA,
    icon: <DesktopIconLight size="xl" />,
    description: localizedF("Support unmanaged endpoints with a temporary agent for quick and easy remote access."),
    helpArticles: [
      {
        label: "Setup Guide: Remote Support",
        href: `${ninjaResourceCdn}/RSM/NinjaRMMRemoteSupportModule.pdf`,
      },
    ],
    hide: () => !isSystemAdmin() || !isFeatureEnabled("remotesupport"),
    isNinja: true,
    configRoute: `${APPS_URL}/remoteSupport`,
  },
  LOCKHART: {
    label: () => "NinjaOne Backup",
    description: localizedF(
      "Backup endpoints wherever they are with a flexible solution that meets your data protection, cost, and RTO objectives every time.",
    ),
    helpArticles: [
      ...getSuggestedArticles(["360045232051"]),
      {
        labelToken: localizationKey("Get started: Backup"),
        action: () => showModal(<GetStartedModal />),
      },
    ],
    logo: APP_LOGOS.NINJA,
    icon: <ServerIconLight size="xl" />,
    hide: () => isITCloud() || !user("canViewAndManageBackupData"),
    isNinja: true,
    configRoute: `${APPS_URL}/backup`,
  },
  // TODO: add helpArticles once ready & approved
  NINJA_PSA: {
    label: () => "NinjaOne PSA",
    description: localizedF("Single pane billing to run your MSP business"),
    logo: APP_LOGOS.NINJA,
    icon: <ReceiptIconLight size="xl" />,
    hide: () => !isUserAllowedToUseNinjaPSAAdministrativeActions(),
    isNinja: true,
    configRoute: `${APPS_URL}/ninjaPSA/general`,
    baseRoute: `${APPS_URL}/ninjaPSA`,
    subtabs: [
      {
        label: localizedF("General"),
        route: `${APPS_URL}/ninjaPSA/general`,
      },
      {
        label: localizedF("Products"),
        route: `${APPS_URL}/ninjaPSA/products`,
      },
      {
        label: localizedF("Agreement Templates"),
        route: `${APPS_URL}/ninjaPSA/agreementTemplates`,
      },
      {
        label: localizedF("Integrations"),
        route: `${APPS_URL}/ninjaPSA/integrations`,
      },
    ],
  },
  CLOUDBERRY: {
    label: () => "CloudBerry",
    description: localizedF("Backup critical servers and endpoints using MSP360's basic cloud backup solution."),
    helpArticles: getSuggestedArticles(["360003547072"]),
    logo: APP_LOGOS.CLOUDBERRY,
    hide: () => isITCloud() || !isFeatureEnabled("cloudberry") || (!isSystemAdmin() && !user("canConfigureCloudberry")),
    configRoute: `${APPS_URL}/cloudberry`,
  },
  CONNECTWISE_CONTROL: {
    label: localizedF("ConnectWise Control"),
    logo: APP_LOGOS.CONNECTWISE,
    description: localizedF(
      "Launch a remote connection through the Connectwise Control web portal directly from Ninja.",
    ),
    helpArticles: getSuggestedArticles(["360048873071"]),
    hide: () => !user("canConfigureConnectWiseControl"),
    configRoute: `${APPS_URL}/connectWiseControl`,
  },
  TEAMVIEWER: {
    label: localizedF("TeamViewer"),
    description: localizedF(
      "Take control of any managed endpoint quickly and securely at the click of a button with Ninja’s TeamViewer integration.",
    ),
    helpArticles: getSuggestedArticles(["360035555612"]),
    logo: APP_LOGOS.TEAM_VIEWER,
    hide: () => !isFeatureEnabled("team_viewer") || !user("canConfigureTeamViewer"),
    configRoute: `${APPS_URL}/teamviewer`,
  },
  SPLASHTOP: {
    label: localizedF("Splashtop"),
    description: localizedF("Take control of managed endpoints with NinjaOne's Splashtop integration."),
    helpArticles: getSuggestedArticles(["115002744666"]),
    logo: APP_LOGOS.SPLASHTOP,
    hide: () => !isFeatureEnabled("splashtop") || !user("canConfigureSplashtop"),
    configRoute: `${APPS_URL}/splashtop`,
  },
  ITGLUE: {
    label: localizedF("ITGlue"),
    logo: APP_LOGOS.IT_GLUE,
    description: localizedF(
      "Automatically sync critical endpoint data, including device details, from Ninja into IT Glue.",
    ),
    helpArticles: getSuggestedArticles(["214971166"]),
    hide: () => !user("canConfigureITGlue"),
    configRoute: `${APPS_URL}/itglue`,
  },
  // TODO: add helpArticles once ready & approved
  GRAVITYZONE: {
    label: () => "Bitdefender GravityZone",
    description: localizedF(
      "Deploy, manage, and automate Bitdefender’s industry-leading security solution directly in NinjaOne.",
    ),
    helpArticles: getSuggestedArticles(["115003138691", "4425077024653", "20537484651277"]),
    logo: APP_LOGOS.BITDEFENDER,
    hide: () => !isFeatureEnabled("gravityzone") || !user("canConfigureGravityZone"),
    configRoute: `${APPS_URL}/gravityZone`,
  },
  WEBROOT: {
    label: localizedF("Webroot"),
    description: localizedF(
      "Deploy, scan, and remediate threats directly in Ninja using Webroot’s easy-to-manage cybersecurity suite.",
    ),
    helpArticles: getSuggestedArticles(["115002716623"]),
    logo: APP_LOGOS.WEBROOT,
    hide: () => isITCloud() || !isSystemAdmin(),
    configRoute: `${APPS_URL}/webroot`,
  },
  SENTINEL_ONE: {
    label: () => "SentinelOne",
    description: localizedF(
      "Securely manage and streamline business processes with total endpoint detection and response (EDR).",
    ),
    helpArticles: getSuggestedArticles(["10220618391565"]),
    logo: APP_LOGOS.SENTINEL_ONE,
    hide: () => !isFeatureEnabled("sentinel_one"),
    hideInCatalog: () => !user("canConfigureSentinelOne"),
    configRoute: `${APPS_URL}/sentinelOne`,
  },
  NINJAREMOTE: {
    label: () => "NinjaOne Remote",
    description: localizedF("Support endpoints with NinjaOne's native remote access tool"),
    helpArticles: getSuggestedArticles(["360056410972"]),
    logo: APP_LOGOS.NINJA,
    isNinja: true,
    icon: <StyledFontAwesomeIcon icon={faNinjaControl} fontSize={32} />,
    hide: () => !user("canConfigureNinjaConnect") || !isFeatureEnabled("ninja_control"),
    configRoute: `${APPS_URL}/ninjaRemote`,
  },
  CROWDSTRIKE: {
    label: () => "CrowdStrike",
    description: localizedF(
      "Provides cloud workload and endpoint security, threat intelligence, and cyberattack response services (EDR).",
    ),
    helpArticles: getSuggestedArticles(["16288676347149"]),
    logo: APP_LOGOS.CROWDSTRIKE,
    hide: () => !isFeatureEnabled("crowdstrike"),
    hideInCatalog: () => !user("canConfigureCrowdStrike"),
    configRoute: `${APPS_URL}/crowdstrike`,
  },
  WINGET: {
    label: () => "winget",
    description: localizedF(
      "Install and upgrade applications on Windows 10+ directly in Ninja using the Windows Package Manager.",
    ),
    helpArticles: getSuggestedArticles(["22965144750989"]),
    logo: APP_LOGOS.WINGET,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("win_3pp_patching_winget")),
    configRoute: `${APPS_URL}/winget`,
  },
  WARRANTY_TRACKING: {
    label: () => `NinjaOne ${warrantyTrackingProductNameToken}`,
    description: localizedF(warrantyTrackingAppDescriptionToken),
    logo: APP_LOGOS.NINJA,
    isNinja: true,
    icon: <FileCertificate size="xl" />,
    hide: () => !isSystemAdmin(),
    configRoute: `${APPS_URL}/warrantyTracking`,
    helpArticles: getSuggestedArticles(["27316499175309"]),
  },
  VULNERABILITY_MANAGEMENT: {
    label: localizedF("Vulnerability Importer"),
    description: localizedF("Import scan data from third-party vulnerability management software."),
    helpArticles: getSuggestedArticles(["26331558954509"]),
    icon: <UploadIcon size="xl" />,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("vulnerability_management")),
    configRoute: `${APPS_URL}/vulnerabilityManagement`,
  },
  VULNERABILITY_MANAGEMENT_RAPID7: {
    label: () => "Rapid7",
    description: localizedF(
      "Configure Rapid7 scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
    helpArticles: getSuggestedArticles(["26331594477197"]),
    logo: APP_LOGOS.RAPID7,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("vulnerability_management")),
    configRoute: `${APPS_URL}/rapid7`,
  },
  VULNERABILITY_MANAGEMENT_QUALYS: {
    label: () => "Qualys",
    description: localizedF(
      "Configure Qualys scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
    helpArticles: getSuggestedArticles(["26331660240653"]),
    logo: APP_LOGOS.QUALYS,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("vulnerability_management")),
    configRoute: `${APPS_URL}/qualys`,
  },
  VULNERABILITY_MANAGEMENT_TENABLE: {
    label: () => "Tenable",
    description: localizedF(
      "Configure Tenable scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
    helpArticles: getSuggestedArticles(["26331671031053"]),
    logo: APP_LOGOS.TENABLE,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("vulnerability_management")),
    configRoute: `${APPS_URL}/tenable`,
  },
  NINJA_AI: {
    label: () => "NinjaOne AI",
    logo: APP_LOGOS.NINJA,
    icon: <AIIconLight size="xl" />,
    description: localizedF("AI-powered features to improve the NinjaOne technician experience."),
    hide: () => !(isSystemAdmin() && isFeatureEnabled("patch_sentiment_analysis")),
    isNinja: true,
    configRoute: `${APPS_URL}/ninjaAI`,
  },
  VULNERABILITY_MANAGEMENT_SPOTLIGHT: {
    label: () => "CrowdStrike Spotlight",
    description: localizedF(
      "Configure CrowdStrike Spotlight scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
    helpArticles: getSuggestedArticles(["27491852723725"]),
    logo: APP_LOGOS.SPOTLIGHT,
    hide: () => !(isSystemAdmin() && isFeatureEnabled("vulnerability_management")),
    configRoute: `${APPS_URL}/crowdstrikeSpotlight`,
  },
  NINJA_PATCHING: {
    label: () => "NinjaOne Patching",
    logo: APP_LOGOS.NINJA,
    icon: <NinjaPatchingSvg />,
    description: localizedF(
      "Automate OS and software updates to ensure system security and compliance across your entire environment.",
    ),
    hide: () => !isSystemAdmin(),
    isNinja: true,
    configRoute: `${APPS_URL}/ninjaPatching`,
    helpArticles: getSuggestedArticles(["360060061732"]),
    hideAppStatus: true,
  },
  ...psaApps,
}

export const getThirdPartyApps = (appEnumsList, psaType) =>
  compose(
    map(({ name, ...rest }) => ({
      ...rest,
      name: name === "PSA" ? psaType : name,
    })),
    filter(({ name }) => !appInfo[name === "PSA" ? psaType : name]?.isNinja),
    filter(({ name }) => !(name === "PSA" && psaType === "NONE")),
  )(appEnumsList)
